import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Details Surrounding July 3rds Internet Service Interruption 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Details Surrounding July 3rds Internet Service Interruption</h1>
        
        <p>Dear valued herd members,</p>

<p>On behalf of our team at Purple Cow Internet, we extend our sincere gratitude for your continued support and patience during the service interruption experienced on July 3rd. We are always committed to transparency, and want to keep you in the loop with everything that transpired.</p>

<p>At approximately 10:00 am on July 3rd, we detected a growing number of herd members experiencing internet connection issues. In response, we promptly followed our standard protocol for addressing customer connectivity problems. However, it became evident that an unusually high number of individuals across the province were affected. Consequently, we initiated contact with our last mile provider to investigate the matter further.</p>

<p>Initially, our provider was unable to find the root cause of the widespread connectivity loss, hence our inability to effectively communicate to you the issue and resolution timeline. It was not until 2:00pm that they identified a software issue within their system. This particular software was inadvertently disabling internet access for our customers one by one and was also doing the same to other internet service providers customers as well. Once this problem was identified, our provider worked to rectify it. Unfortunately, the resolution process extended until approximately 7:30 am the following day, when connectivity was fully restored for all affected customers.</p>

<p>Following the incident, our provider provided the following statement: "There was a script error in the billing system. The issue has been identified, rectified, and further investigation is being conducted to prevent its recurrence in the future." Despite our request for additional details, no further information was shared, no apology and they have chosen not to make any public statements at this time.</p>

<p>With that said we wanted to reach out to you and give you all the information we know and give our sincerest apology to you for the interruption. This is why we are proactively working towards plans to be able to serve you better in the future.</p>

<p>We also want to emphasize that Purple Cow's overarching objective is to drive down the cost of internet services. Your decision to join the Purple Cow community sends a powerful message to larger industry players, compelling them to lower their prices and improve customer service. It is through the unwavering dedication of you and our fellow herd members that Purple Cow can operate and continually devise strategies to reduce internet costs.</p>

<p>With love and a big hug, we appreciate you.</p>

         <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
